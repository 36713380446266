import {
  ModalProvider,
  ToastContextProvider,
  TooltipProvider,
} from '@leland-dev/leland-ui-library';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { type AppProps, type NextWebVitalsMetric } from 'next/app';
import { useRouter } from 'next/router';
import React, { type ReactElement, useEffect } from 'react';

import { DisplaySessionSummaryModal } from '../components/SessionSummaries/DisplaySessionSummaryModal';
import { AuthContextProvider } from '../context/AuthContext';
import { FeatureInteractionsProvider } from '../context/FeatureInteractionsContext';
import { LexContextProvider } from '../context/LexContext';
import { ApolloProviderWithAuth0 } from '../lib/ApolloProviderWithAuth0';
import { Auth0ProviderWrapper } from '../lib/Auth0ProviderWrapper';
import { appInsights, reactPlugin } from '../utils/analytics/app-insights';
import { useAttribution } from '../utils/analytics/attribution';
import mxpnl from '../utils/analytics/mixpanel';
import { usePageView } from '../utils/analytics/usePageView';
import { logException } from '../utils/exception';
import { init as initNProgress } from '../utils/nprogress';
import { init as initRollbar } from '../utils/rollbar';

/* eslint-disable import/order */
import 'nprogress/nprogress.css'; //styles of nprogress
import '@sendbird/uikit-react/dist/index.css'; // sendbird styles
import '../styles/app.css';
/* eslint-enable import/order */

const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FB_PIXEL_ID ?? '';

export function reportWebVitals(metric: NextWebVitalsMetric) {
  appInsights.trackMetric(
    { name: metric.name, average: metric.value },
    {
      url: window.location.href,
    },
  );
}

const MyApp = ({ Component, pageProps }: AppProps): ReactElement => {
  const router = useRouter();

  useEffect(() => {
    initRollbar();
    initNProgress();
    void mxpnl.init();
  }, []);

  useEffect(() => {
    // @ts-expect-error `lelandVersion` does not exist on `window`
    window.lelandVersion = process.env.NEXT_PUBLIC_LELAND_VERSION;
  }, []);

  // Track page views
  usePageView();

  // Track request attribution
  useAttribution();

  // Initialize FB Pixel
  useEffect(() => {
    void import('react-facebook-pixel')
      .then((module) => module.default)
      .then((ReactPixel) => {
        try {
          ReactPixel.init(FB_PIXEL_ID);
          ReactPixel.pageView();
          router.events.on('routeChangeComplete', () => ReactPixel.pageView());
        } catch (e) {
          logException(`FB Pixel Error = ${e}`);
        }
      });
  });

  return (
    <Auth0ProviderWrapper>
      <ApolloProviderWithAuth0 pageProps={pageProps as unknown}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <ToastContextProvider>
            <TooltipProvider>
              <AuthContextProvider>
                <LexContextProvider>
                  <FeatureInteractionsProvider>
                    <ModalProvider router={router}>
                      <DisplaySessionSummaryModal />
                      <Component {...pageProps} />
                    </ModalProvider>
                  </FeatureInteractionsProvider>
                </LexContextProvider>
              </AuthContextProvider>
            </TooltipProvider>
          </ToastContextProvider>
        </AppInsightsContext.Provider>
      </ApolloProviderWithAuth0>
    </Auth0ProviderWrapper>
  );
};

export default MyApp;
